// -------------- CA EVENT Logic -------------- //
// import { isDateInRange } from '../utils/isDateInRange';
// import {
//   eventStartDate as startDate,
//   eventEndDate as endDate,
// } from './eventDates';
// const isInRange = isDateInRange(startDate, endDate);
// ------------------------------------------------//

const DISCOUNTS = {
  '20%': 0.2,
  '30%': 0.3,
  '40%': 0.4,
  '50%': 0.5,
  '60%': 0.6,
  BNDLE: 0.6,
  CSS: 0.6,
  'FOUNDERS - NEW': 0.6,
  Military: 0.6,
  'Prev Tx': 0.7,
};

// -------------- CA EVENT Logic -------------- //
// if (isInRange) {
//     DISCOUNTS['CA EVENT'] = 0.6;
//     DISCOUNTS['CA EVENT - NEW'] = 0.6;
// }
// ------------------------------------------------//

// -------- BYOB Discount Logic -------- //
// When the BYOB discount is selected, when 3 body areas are selected then the discount drop to 60% off, excluding bundles & full body. Otherwise, the discount is 50% off.
if (process.env.REACT_APP_BYOB_ENABLED === 'true') {
  DISCOUNTS.BYOB = 0.6;
}

export default DISCOUNTS;

// --- NEW - 2025 Bundles Discount Logic --- //
export const DISCOUNTED_RATIO = {
  vip_bundle: 0.62894,
  premier_bundle: 0.62972,
  essentials_bundle: 0.64085,
  glow_bundle: 0.6495,
  glam_bundle: 0.65265,
  glam_plus_bundle: 0.65121,
  bare_down_there_bundle: 0.62133,
  time_saver_bundle: 0.6109,
  mog_bundle: 0.62294,
  elite_bundle: 0.59992,
  exec_bundle: 0.61261,
  full_body: 0.5,
};
