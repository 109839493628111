export const SelectLender = {
  name: 'Select Lender',
  rate: { min: 0, max: 0 },
  downpayment: 0, // See notes below // Important
  minLoanRequired: {
    24: 0,
    36: 0,
  },
  financePlan: [24, 36],
  canSplit: false,
  display: true,
};

// -----  Regarding downpayment property:
// 0 means down payment allowed but not required
// Less than 0 means down payment required and it is % of the total amount
// Greater than 0 means down payment required and it is a fixed amount

export const AlphaeonPrime = {
  name: 'Alphaeon Prime',
  rate: { min: 0, max: 0 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const ClarityPayNearPrime = {
  name: 'ClarityPay NearPrime',
  rate: { min: 0.0799, max: 0.0799 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const ClarityPayPrime = {
  name: 'ClarityPay Prime',
  rate: { min: 0, max: 0 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const ClarityPaySEC = {
  name: 'ClarityPay SEC',
  rate: { min: 0.2499, max: 0.2499 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const ClarityPayT = {
  name: 'ClarityPayT',
  rate: { min: 0.2499, max: 0.2499 },
  downpayment: 0.04,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const Concora = {
  name: 'Concora',
  rate: { min: 0.249, max: 0.249 },
  downpayment: 0,
  minLoanRequired: {
    36: 1000,
  },
  financePlan: [36],
  canSplit: true,
  display: false,
};

export const CoveredCare = {
  name: 'Covered Care SEC',
  rate: { min: 0.179, max: 0.249 },
  downpayment: 0.05,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const CoveredCareNoDownPayment = {
  name: 'Covered Care SEC - No Down Pmt',
  rate: { min: 0.179, max: 0.249 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

// export const CoveredCarePrime = {
//   name: 'Covered Care Prime 36',
//   rate: { min: 0, max: 0 },
//   downpayment: 0,
//   minLoanRequired: {
//     36: 3000,
//   },
//   financePlan: [36],
//   canSplit: true,
//   display: true,
// };

export const CoveredCareNearPrime = {
  name: 'Covered Care NearPrime',
  rate: { min: 0.079, max: 0.079 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const HFDT = {
  name: 'HFDT',
  rate: { min: 0.2499, max: 0.2499 },
  downpayment: 99, // this means it is a fixed amount $99
  minLoanRequired: {
    36: 1000,
  },
  financePlan: [36],
  canSplit: true,
  display: true,
};

export const PatientFi = {
  name: 'PatientFi',
  rate: { min: 0, max: 0 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const SynchronyPrime = {
  name: 'Synchrony Prime',
  rate: { min: 0, max: 0 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const Synchrony60 = {
  name: 'Synchrony 60',
  rate: { min: 0, max: 0 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
    60: 4000,
  },
  financePlan: [24, 36, 60],
  canSplit: true,
  display: true,
};

// export const UGAPrime = {
//   name: 'UGA Prime',
//   rate: { min: 0, max: 0 },
//   downpayment: 0,
//   minLoanRequired: {
//     24: 1000,
//     36: 3000,
//   },
//   financePlan: [24, 36],
//   canSplit: true,
//   display: true,
// };

export const UGASecondary = {
  name: 'UGA Secondary',
  rate: { min: 0.18, max: 0.18 },
  downpayment: 0,
  minLoanRequired: {
    24: 1000,
    36: 3000,
  },
  financePlan: [24, 36],
  canSplit: true,
  display: true,
};

export const UGAT = {
  name: 'UGAT',
  rate: { min: 0.18, max: 0.18 },
  downpayment: 0.04,
  minLoanRequired: {
    36: 750,
  },
  financePlan: [36],
  canSplit: true,
  display: true,
};

export const myMILAN = {
  name: 'myMILAN',
  rate: { min: 0.2499, max: 0.2499 },
  downpayment: 0,
  minLoanRequired: {
    24: 0,
  },
  financePlan: [24],
  canSplit: false,
  display: false,
};

Concora.display =
  process.env.REACT_APP_CONCORA_ENABLED === 'true' ? true : false;
myMILAN.display =
  process.env.REACT_APP_SPLIT_FINANCE_FEATURE === 'true' ? true : false; //myMilan uses split finance feature
UGAT.display = process.env.REACT_APP_UGAT_ENABLED === 'true' ? true : false;

export const defaultLenders = [
  AlphaeonPrime,
  ClarityPayNearPrime,
  ClarityPayPrime,
  ClarityPaySEC,
  ClarityPayT,
  Concora,
  CoveredCare,
  CoveredCareNoDownPayment,
  CoveredCareNearPrime,
  HFDT,
  PatientFi,
  SynchronyPrime,
  Synchrony60,
];
