import { DISCOUNTS } from '../constants';
import { itemFinalCost } from './itemFinalCost';
import { getActiveFeePercentage } from './getActiveFeePercentage';
import { getLowestPriceItem } from './getLowestPriceItem';
import { PRIVATE_SALES } from '../constants';

/**
 * This function updates each item object in the option.items array
 * @param {object} active
 * @param {object} option
 * @param {number} cohort
 * @returns {void}
 */
export const updateEachItem = (active, option, cohort) => {
  // -------------- CA EVENT OR FOUNDERS Logic -------------- //
  const EVENT_DISCOUNT = 'FOUNDERS - NEW'; // OR 'CA EVENT - NEW'
  const isNewClient = active.discount === EVENT_DISCOUNT;
  const lowestPriceItem = isNewClient ? getLowestPriceItem(option, cohort) : {};
  // ------------------------------------------------//

  const activePrivateSale = PRIVATE_SALES[active.privateSale]; // PS250 or PS500
  // Private Sale is not applicable if
  // 1. The option includes a bundle area
  // 2. The option includes an item with a discount of BYOB
  const isPrivateSaleRestricted = option.items.some(
    (item) => item.parentAreaId === 'bundles' || item.discount === 'BYOB'
  );

  option.privateSale = isPrivateSaleRestricted ? 0 : activePrivateSale;

  const itemPrivateSale = option.privateSale / option.items.length; // Private Sale portion of each item
  const fee = getActiveFeePercentage(active); // Get the active fee percentage

  option.items.forEach((item) => {
    const price = item.prices[`cohort_${cohort}`];
    item.monthlyPrice = Math.round(price / option.financePlan);
    // --------------- Military Discount For A Bundle Area -----------------//
    const isBundle = item.parentAreaId === 'bundles' && item.id !== 'full_body';
    DISCOUNTS['Military'] = isBundle ? 0.64 : 0.6;
    // DISCOUNTS['Event'] = isBundle ? 0.64 : 0.6
    // ----------------------------------------------------------------//
    item.discountedMonthlyPrice = Math.round(
      (price * (1 - DISCOUNTS[item.discount])) / option.financePlan
    );
    item.finalCost = itemFinalCost(
      item,
      itemPrivateSale,
      cohort,
      lowestPriceItem // For CA EVENT OR FOUNDERS Logic
    );
    item.fee = fee > 0 ? item.finalCost * fee : 0;
  });
};
