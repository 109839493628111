import { initialOptionValues } from '../constants';
import {
  calculateSum,
  handleDownAndMonthlyPmt,
  updateEachItem,
  isValidSynchrony60,
  getInterestRate,
  processDownPayment,
} from '.';
import { handlePrimaryLender, handleSecondaryLender } from './mmf';
// import { pifDiscount } from '../constants'; // Deprecated

export const updateOptionProps = (option, active) => {
  if (!option) return; // Sometimes option is being passed as undefined for some reason
  if (option.items.length === 0) return initialOptionValues;

  const cohort = active.state.cohort; // cohort number 1, 2, 3

  // option.financePlan = getFinancePlan(active, option); // Deprecated
  option.financePlan = active.financePlan;

  // DISCOUNTS['BNDLE'] = active.preSale ? 0.64 : 0.6

  // -> PIF logic goes here if needed

  updateEachItem(active, option, cohort);

  // Note: for option.fee Do NOT do finalTotalCost * fee -> it is not correct
  // Keep this line after updateEachItem() to get the correct fee value
  option.fee = calculateSum(option.items.map((item) => item.fee));

  option.totalOriginalCost = calculateSum(
    option.items.map((item) => item.prices[`cohort_${cohort}`])
  );
  const accurateFinalTotalCost =
    calculateSum(option.items.map((item) => item.finalCost)) + option.fee;
  option.finalTotalCost = Math.round(accurateFinalTotalCost - 0.5); // Subtracting 0.5 would make the .5 round down
  // option.finalTotalCost = accurateFinalTotalCost

  // Total Financed is the final total cost minus Today's Payment
  option.totalFinanced = option.finalTotalCost - option.todaysPayment;
  // ------------- Down Payment Logic ------------------ //
  processDownPayment(active, option);

  // ----- Total Savings ----- //
  option.totalSavings =
    option.totalOriginalCost - option.finalTotalCost + Math.floor(option.fee);

  // --------------- Unique Lenders Logic --------------- //
  // Keep this logic here after the finalTotalCost is calculated
  const isSynchrony60 = isValidSynchrony60(active, option);
  if (isSynchrony60) {
    option.financePlan = 60;
  }
  // if (isSynchrony60) {
  //   option.financePlan = 60;
  // } else if (
  //   active.lender.name === AlphaeonPrime.name &&
  //   active.financePlan === 36
  // ) {
  //   option.financePlan = 28;
  // }
  // ---------------------------------------------------- //
  const { minRate, maxRate } = getInterestRate(active, isSynchrony60);

  // ******************* Split Finance Logic *********************** //
  // This is useful when deleting areas from an Option Tile -> This will decrease the final total cost and the total financed
  // Also, if Split Finance check box is unchecked
  const { isEnabled, primaryLender, secondaryLender } = option.splitLoan;
  if (
    primaryLender.approvedAmount >= option.finalTotalCost ||
    // primaryLender.approvedAmount > option.totalFinanced || // This line is causing an issue
    !isEnabled
  ) {
    primaryLender.approvedAmount =
      primaryLender.financedAmount =
      secondaryLender.financedAmount =
        0;
    primaryLender.monthlyPayment = secondaryLender.monthlyPayment = [];
  }
  const isSplitLoanActive =
    isEnabled && option.items.length > 0 && primaryLender.approvedAmount > 0;
  if (isSplitLoanActive) {
    handlePrimaryLender(active, option, primaryLender, minRate, maxRate);
    handleSecondaryLender(
      active,
      option,
      primaryLender,
      secondaryLender,
      accurateFinalTotalCost
    );

    // ---- $$$$ Overall Monthly Payment / Primary & Secondary combined $$$$ ---- //
    option.monthlyPayment =
      primaryLender.monthlyPayment.length === 2 // This means it's a range (has 2 elements)
        ? [
            primaryLender.monthlyPayment[0] + secondaryLender.monthlyPayment[0],
            primaryLender.monthlyPayment[1] + secondaryLender.monthlyPayment[0],
          ]
        : // OR, No range
          [primaryLender.monthlyPayment[0] + secondaryLender.monthlyPayment[0]];

    // This is very important. Otherwise, the nested object won't update
    option.splitLoan.primaryLender = { ...primaryLender };
    option.splitLoan.secondaryLender = { ...secondaryLender };

    // Update Total Financed
    option.totalFinanced =
      primaryLender.financedAmount + secondaryLender.financedAmount;
  } else {
    // Default --- ******************** NO Split Loan applied ********************** //
    handleDownAndMonthlyPmt(
      active,
      option,
      minRate,
      maxRate,
      accurateFinalTotalCost
    );
  }

  option.additionalDownPmt = option.todaysPayment - option.requiredDownPmt;
  // ******* IMPORTANT ********* // KEEP THIS LOGIC AT THE END // To avoid overwriting the prop somewhere else.
  // $$ The logic below is only to make the sum of the items in the Manager's section match the displayed Your Cost
  // ------------------------------------- ------------------------------------- ------------------------------------- //
  const residue = option.finalTotalCost - accurateFinalTotalCost; // Could be a few cents more or less
  if (residue !== 0) option.items[0].finalCost += residue;
  // ------------------------------------- ------------------------------------- ------------------------------------- //
  // if (option.items.length > 0) console.log('updateOptionProps', option)
  return { ...option };
};
