import { DISCOUNTS } from '../constants';
import { DISCOUNTED_RATIO } from '../constants/DISCOUNTS';

/**
 * Item Final Cost
 * @param {object} item
 * @param {number} itemPrivateSale
 * @param {number} cohort 1, 2, 3
 * @param {object} lowestPriceItem // For CA EVENT Logic
 * @returns {number}
 */
export const itemFinalCost = (
  item,
  itemPrivateSale,
  cohort,
  lowestPriceItem
) => {
  const isBundle = item.parentAreaId === 'bundles';
  const isFullBody = item.id === 'full_body';

  // This is the default behavior for the BNDLE & Military discounts
  if (isBundle) {
    DISCOUNTS['Military'] = isFullBody ? 0.6 : 0.64;
    DISCOUNTS['BNDLE'] = isFullBody ? 0.5 : 0.6;
  } else {
    DISCOUNTS['Military'] = 0.6;
    DISCOUNTS['BNDLE'] = 0.6;
  }

  // DISCOUNTS['Event'] = isBundle ? 0.64 : 0.6

  let discount = DISCOUNTS[item.discount];

  //   ---- NEW - 2025 Bundles Discount Logic ---- //
  // Apply the new 2025 discounted ratio. Each bundle has a different discount ratio.
  if (isBundle && !isFullBody) {
    if (item.discount === 'BNDLE') {
      discount = DISCOUNTED_RATIO[item.id];
    } else if (item.discount === 'Military') {
      // The military discount is 10% of the Bundle Discounted Price
      discount = 0.1 + 0.9 * DISCOUNTED_RATIO[item.id];
    }
  }

  // -------------- CA EVENT OR FOUNDERS Logic -------------- //
  const EVENT_DISCOUNT = 'FOUNDERS - NEW'; // OR 'CA EVENT - NEW'
  // If the item is the lowest price item and the discount is 'CA EVENT - NEW'. Apply 50% discount
  const is50Discount =
    item.discount === EVENT_DISCOUNT && item.id === lowestPriceItem?.id;
  if (is50Discount) {
    discount = 0.5;
  }
  // ------------------------------------------------//

  return +(
    (item.prices[`cohort_${cohort}`] - itemPrivateSale) *
    (1 - discount)
  ).toFixed(2);
};
